import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import carrierMasterCarrier from "./service-level-service";
import commonAPIService from "../../common/js/common-api-service";
import carrierMasterComp from "../../company/carrier-master-comp.vue";
import AddFavourite from "../../utility/js/addFav";
export default {
  data: () => ({
    name: "ServiceLevelAdd",
    backSlash: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    selectedOrderType: "",
    selectedCarrier: "",
    carrierData: [],
    refreshOutlined: true,
    serviceLevelsData: [],
    showUPSDetails: true,
    showUSPSDetails: false,
    outboundAccName: "",
    outboundAccNum: "",
    selectedAccountOut: "",
    accountData: [],
    thirdPartyBillOut: false,
    thirdPartyAccNoOut: "",
    thirdPartyAccNoOut_Enabled: false,
    upsAPIThirdParPostal: "",
    upsAPIThirdParPostal_Enabled: false,
    returnAccName: "",
    returnAccName_Enabled: false,
    returnAccNum: "",
    returnAccNum_Enabled: false,
    selectedAccountRet: "",
    thirdPartyBillRet: false,
    thirdPartyAccNoRet: "",
    thirdPartyAccNoRet_Enabled: false,
    countryData: [],
    upsAPIThirdParAccCountryCode: "",
    upsAPIThirdPartyPostalCode_Enabled: false,
    upsAPIThirdParPostalRet: "",
    upsAPIThirdParPostalRet_Enabled: false,
    upsAPIThirdParAccCountryCodeRet: "",
    upsAPIThirdPartyPostalCodeRet_Enabled: false,
    pass: null,
    slbu_id: null,
    orderReqCarrierCode: "",
    orderReqCarrierCode_Enabled: false,
    orderReqServiceLevelCode: "",
    orderReqServiceLevelCode_Enabled: false,
    activateCarrWebServ: false,
    activateCarrWebServ_Enabled: false,
    serviceLevelCode: "",
    serviceLevelCode_Enabled: false,
    activeServiceLevel: false,
    activeServiceLevel_Enabled: false,
    domesticServiceLevel: false,
    domesticServiceLevel_Enabled: false,
    serviceLevelDetails: [],
    ei_on: null,
    freightCarrierServiceLevel: false,
    freightCarrierServiceLevel_Enabled: false,
    apiinterfaceSetting: "",
    apiinterfaceSetting_Enabled: false,
    selectedCountry: "",
    accountName: "",
    accAllowforMultiProj: false,
    accountNumber: "",
    upsPortalName: "",
    upsPortalPass: "",
    upsAccessLicense: "",
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    newAccount: false,
    showFedExDetails: false,
    showNonUPSDetails: false,
    accountId: 0,
    default_sl: null,
    uspsMailerID: "",
    uspsMailerID_Enabled: true,
    uspsMerchantID: "",
    uspsMerchantID_Enabled: true,
    accountDetailsOut: [],
    accountDetailsRet: [],
    companyAddress: "",
    accAddress1: "",
    accAddress2: "",
    accCity: "",
    accState: "",
    accZip: "",
    accCountry: "",
    companyAddressRet: "",
    accAddress1Ret: "",
    accAddress2Ret: "",
    accCityRet: "",
    accStateRet: "",
    accZipRet: "",
    accCountryRet: "",
    accountSelectedDetailsOut: false,
    accountSelectedDetailsRet: false,
    outBoundAccID: 0,
    returnAccID: 0,
    returnServices: 0,
    isPasswordShow: false,
    isPasswordShowUPS: false,
    actionList: [],
    mainPage: "",
    subPage: "",
    back: true,
    subPageUrl: "",
    pageUrl: "",
    appPageName: "",
    backSlashParent: true,
    newAccOutbound: true,
    newAccReturn: true,
    max100Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 100 || "Max Length must be 100 characters or less"],
    max5Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 5 || "Max Length must be 5 characters or less"],
    max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less"],
    max200Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 200 || "Max Length must be 200 characters or less"],
    max60Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 60 || "Max Length must be 60 characters or less"],
    max30Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 30 || "Max Length must be 30 characters or less"],
    max30NoReqRule: [(v) => (v || "").length <= 30 || "Max Length must be 30 characters or less"],
    max20Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
    max20NoReqRule: [(v) => (v || "").length <= 20 || "Max Length must be 20 characters or less"],
    max36Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 36 || "Max Length must be 36 characters or less"],
    carrierSelected: true,
    accDetOutBound: [],
    accDetReturn: [],
    accountAction: "",
    popupTitle: "",
    CXValid: false,
    webServiceAPIValid: false,
    nonUPSValid: false,
    UPSValid: false,
    fedExValid: false,
    USPSValid: false,
    formValidated: false,
    selectedProject: "",
    selectedBusiness: "",
    showAPISection: false,
    compName: "",
    carrierCompCalled: false,
    singleCarrierDetUPSOut: [],
    singleCarrierDetFedExOut: [],
    singleCarrierDetUPSRet: [],
    singleCarrierDetFedExRet: [],
    singleCarrierDet: [],
    textAPIIntegration: "",
    carrierShortName: "",
    carrierKey: 0,
    accountType: "",
    newCarrierID: 0,
  }),
  async created() {
    let dataSimple = await AddFavourite.getPageAction(this.userId, "app-service-level");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = dataSimple.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    let countryStateData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.countryData = countryStateData.CountryInfo;
  },
  mounted() {
    //Route Params
    this.serviceLevelsData = JSON.parse(atob(this.$route.params.serviceLevelsAdd));
    this.selectedOrderType = this.serviceLevelsData.ordertype;
    this.selectedProject = this.serviceLevelsData.project;
    this.selectedBusiness = this.serviceLevelsData.business;
    this.getCarrierListDetails();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.serviceLevelsData = [];
      this.serviceLevelsData = JSON.parse(atob(this.$route.params.serviceLevelsAdd));
      this.selectedOrderType = this.serviceLevelsData.ordertype;
      this.selectedProject = this.serviceLevelsData.project;
      this.selectedBusiness = this.serviceLevelsData.business;
      this.selectedCarrier = "";
      this.serviceLevelCode = "";
      this.activeServiceLevel = false;
      this.activeServiceLevel_Enabled = false;
      this.domesticServiceLevel = false;
      this.domesticServiceLevel_Enabled = false;
      this.freightCarrierServiceLevel = false;
      this.freightCarrierServiceLevel_Enabled = false;
      this.orderReqCarrierCode = "";
      this.orderReqCarrierCode_Enabled = false;
      this.orderReqServiceLevelCode = "";
      this.orderReqServiceLevelCode_Enabled = false;
      this.activateCarrWebServ = false;
      this.activateCarrWebServ_Enabled = false;
      this.apiinterfaceSetting = "";
      this.apiinterfaceSetting_Enabled = false;
      this.showUPSDetails = true;
      this.outboundAccName = "";
      this.outboundAccNum = "";
      this.selectedAccountOut = "";
      this.thirdPartyBillOut = false;
      this.thirdPartyAccNoOut = "";
      this.thirdPartyAccNoOut_Enabled = false;
      this.upsAPIThirdParPostal = "";
      this.upsAPIThirdParPostal_Enabled = false;
      this.upsAPIThirdParAccCountryCode = "";
      this.upsAPIThirdPartyPostalCode_Enabled = false;
      this.returnAccName = "";
      this.returnAccName_Enabled = false;
      this.returnAccNum = "";
      this.returnAccNum_Enabled = false;
      this.selectedAccountRet = "";
      this.thirdPartyBillRet = false;
      this.thirdPartyAccNoRet = "";
      this.thirdPartyAccNoRet_Enabled = false;
      this.upsAPIThirdParPostalRet = "";
      this.upsAPIThirdParPostalRet_Enabled = false;
      this.upsAPIThirdParAccCountryCodeRet = "";
      this.upsAPIThirdParAccCountryCodeRet_Enabled = false;
      this.carrierSelected = true;
      this.showNonUPSDetails = false;
      this.showFedExDetails = false;
      this.showUSPSDetails = false;
      this.isPasswordShow = false;
      this.isPasswordShowUPS = false;
      this.showAPISection = false;
      this.textAPIIntegration = "";
      this.compName = "";
      this.carrierCompCalled = false;
      this.singleCarrierDet = [];
      this.accountSelectedDetailsOut = false;
      this.accountSelectedDetailsRet = false;
      this.carrierShortName = "";
      this.carrierKey = 0;
      this.accountType = "";
      this.newCarrierID = 0;
      this.newAccOutbound = true;
      this.newAccReturn = true;
      this.closeDialog();
      this.getCarrierListDetails();
      this.$refs.AddServiceLevelForm.resetValidation();
      this.$refs.carrierXilyticInfoForm.resetValidation();
      this.$refs.UPSForm.resetValidation();
      this.$refs.nonUPSForm.resetValidation();
      this.$refs.fedExForm.resetValidation();
      this.$refs.USPSForm.resetValidation();
      if (this.newAccount) this.$refs.AddAccountForm.resetValidation();
    },
    //Getting carrier list data
    async getCarrierListDetails() {
      this.carrierData = [];
      let obj = {
        User_Id: parseInt(this.userId),
        bu_id: parseInt(this.serviceLevelsData.bu_id),
        sbu_id: parseInt(this.serviceLevelsData.sbu_id),
        ord_code: this.serviceLevelsData.ord_code,
      };
      let data = await carrierMasterCarrier.getServiceLvlCarrierList("post", obj);
      if (data.message != "NA") {
        this.carrierData = data;
      }
    },
    //On Carrier Select
    onCarrierSelect() {
      this.getServiceLevelDetails();
      this.carrierSelected = false;
      let carrierDetails = this.carrierData.filter((obj) => obj.sl_id === this.selectedCarrier);
      if (carrierDetails[0].sl_id !== null && carrierDetails[0].sl_id !== undefined) {
        if (carrierDetails[0].sl_id == 19) {
          this.showUPSDetails = false;
          this.showNonUPSDetails = true;
          this.showFedExDetails = false;
          this.showUSPSDetails = false;
        }
      }
    },
    //Getting data based on the carrier selected
    async getServiceLevelDetails(newCarrierID) {
      this.pass = 1;
      this.slbu_id = 0;
      this.serviceLevelDetails = [];
      let obj = {
        User_Id: parseInt(this.userId),
        bu_id: parseInt(this.serviceLevelsData.bu_id),
        sbu_id: parseInt(this.serviceLevelsData.sbu_id),
        sl_id: parseInt(this.selectedCarrier),
        slbu_id: parseInt(this.slbu_id),
        pass: parseInt(this.pass),
        ord_code: this.serviceLevelsData.ord_code,
      };
      let data = await carrierMasterCarrier.getServiceLvlDetails("post", obj);
      if (data.message != "NA") {
        this.serviceLevelDetails = data.Settings[0];
        this.showAPISection = data.Settings[0]?.IsAPISectionVisible;
        this.ei_on = data.Ei_on;
        this.accountData = data.AccountInfo;
        this.carrierShortName = data.Settings[0]?.carrierShortName;
        this.carrierKey = data.Settings[0]?.carrier_key;
        if (this.showAPISection) {
          this.activateCarrWebServ = data.Settings[0]?.ActivateCarrierAPI;
          this.activateCarrWebServ_Enabled = data.Settings[0]?.ActivateCarrierAPI_enable == 1 ? true : false;
        } else {
          this.activateCarrWebServ = false;
          this.activateCarrWebServ_Enabled = false;
        }
        this.activeServiceLevel = this.serviceLevelDetails.Active == 1 ? true : false;
        this.activeServiceLevel_Enabled = this.serviceLevelDetails.Active_enable == 1 ? true : false;
        this.domesticServiceLevel = this.serviceLevelDetails.Domestic;
        this.domesticServiceLevel_Enabled = this.serviceLevelDetails.Domestic_enable == 1 ? true : false;
        this.freightCarrierServiceLevel = this.serviceLevelDetails.Freight;
        this.freightCarrierServiceLevel_Enabled = this.serviceLevelDetails.Freight_enable == 1 ? true : false;
        this.serviceLevelCode = this.serviceLevelDetails.RLP_Service_Level_Code;
        this.serviceLevelCode_Enabled = this.serviceLevelDetails.RLP_Service_Level_Code_enable == 1 ? true : false;
        this.apiinterfaceSetting = this.serviceLevelDetails.API_Interface_Setting;
        this.apiinterfaceSetting_Enabled = this.serviceLevelDetails.API_Interface_Setting_enable == 1 ? true : false;
        // this.orderReqCarrierCode = this.serviceLevelDetails.ccode;
        this.orderReqCarrierCode_Enabled = this.serviceLevelDetails.ccode_enable == 1 ? true : false;
        // this.orderReqServiceLevelCode = this.serviceLevelDetails.slevel;
        this.orderReqServiceLevelCode_Enabled = this.serviceLevelDetails.slevel_enable == 1 ? true : false;
        this.thirdPartyBillOut = this.serviceLevelDetails.bill_3rd;
        this.thirdPartyBillRet = this.serviceLevelDetails.bill_3rd_rtn;
        this.thirdPartyAccNoOut = this.serviceLevelDetails.alt_accountno1;
        this.thirdPartyAccNoOut_Enabled = this.serviceLevelDetails.alt_accountno1_enable == 1 ? true : false;
        this.thirdPartyAccNoRet = this.serviceLevelDetails.alt_accountno1_rtn;
        this.thirdPartyAccNoRet_Enabled = this.serviceLevelDetails.alt_accountno1_rtn_enable == 1 ? true : false;
        this.upsAPIThirdParPostal = this.serviceLevelDetails.ups_zip;
        this.upsAPIThirdParPostal_Enabled = this.serviceLevelDetails.ups_zip_enable == 1 ? true : false;
        this.upsAPIThirdParPostalRet = this.serviceLevelDetails.ups_zip_rtn;
        this.upsAPIThirdParPostalRet_Enabled = this.serviceLevelDetails.ups_zip_rtn_enable == 1 ? true : false;
        this.upsAPIThirdParAccCountryCode = this.serviceLevelDetails.ups_countrycode;
        this.upsAPIThirdParAccCountryCode_Enabled = this.serviceLevelDetails.ups_countrycode_enable == 1 ? true : false;
        this.upsAPIThirdParAccCountryCodeRet = this.serviceLevelDetails.ups_countrycode_rtn;
        this.upsAPIThirdParAccCountryCodeRet_Enabled = this.serviceLevelDetails.ups_countrycode_rtn_enable == 1 ? true : false;
        if (newCarrierID !== null && newCarrierID !== undefined) {
          this.newCarrierID = newCarrierID;
          if (this.accountType == "OutBound") {
            this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === this.newCarrierID);
            this.selectedAccountOut = this.accountDetailsOut[0].account_id;
            this.accountSelectedOut();
            let obj = {
              UserId: this.userId,
              pass: 4,
              account_id: parseInt(this.newCarrierID),
              acct_name: "",
              carrier_key: 0,
              proj_key: 0,
              bu_key: 0,
              accountno: "",
              client_id: "",
              client_secret: "",
              status: true,
            };
            let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
            if (data.message != "NA") {
              this.accountSelectedDetailsOut = true;
              if (this.showUPSDetails) {
                this.singleCarrierDetUPSOut = data[0];
                this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
                this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
              } else if (this.showFedExDetails) {
                this.singleCarrierDetFedExOut = data[0];
                this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
                this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
              }
            }
          } else if (this.accountType == "Return") {
            this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === this.newCarrierID);
            this.selectedAccountRet = this.accountDetailsRet[0].account_id;
            this.accountSelectedRet();
            let obj = {
              UserId: this.userId,
              pass: 4,
              account_id: parseInt(this.newCarrierID),
              acct_name: "",
              carrier_key: 0,
              proj_key: 0,
              bu_key: 0,
              accountno: "",
              client_id: "",
              client_secret: "",
              status: true,
            };
            let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
            if (data.message != "NA") {
              this.accountSelectedDetailsRet = true;
              if (this.showUPSDetails) {
                this.singleCarrierDetUPSRet = data[0];
                this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
                this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
              } else if (this.showFedExDetails) {
                this.singleCarrierDetFedExRet = data[0];
                this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
                this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
              }
            }
          }
        }
        if (this.carrierShortName == "UPS") {
          this.textAPIIntegration = "UPS";
          this.showUPSDetails = true;
          this.showNonUPSDetails = false;
          this.showFedExDetails = false;
          this.showUSPSDetails = false;
        } else if (this.carrierShortName == "FedEx") {
          this.textAPIIntegration = "FedEx";
          this.showFedExDetails = true;
          this.showUPSDetails = false;
          this.showNonUPSDetails = false;
          this.showUSPSDetails = false;
        } else if (this.carrierShortName == "USPS") {
          this.textAPIIntegration = "USPS";
          this.showUSPSDetails = true;
          this.showUPSDetails = false;
          this.showNonUPSDetails = false;
          this.showFedExDetails = false;
        } else {
          this.textAPIIntegration = "";
          this.showNonUPSDetails = true;
          this.showUPSDetails = false;
          this.showFedExDetails = false;
          this.showUSPSDetails = false;
        }
      }
    },
    //Enabled third Party Acc number to fill data for Outbound
    thirdPartyAccNoOutChange() {
      if (!this.thirdPartyBillOut) {
        this.thirdPartyAccNoOut = "";
        this.upsAPIThirdParPostal = "";
        this.upsAPIThirdParAccCountryCode = "";
      } else {
        this.upsAPIThirdParAccCountryCode = "USA";
      }
      this.thirdPartyAccNoOut_Enabled = this.thirdPartyBillOut;
      this.upsAPIThirdParPostal_Enabled = this.thirdPartyBillOut;
      this.upsAPIThirdPartyPostalCode_Enabled = this.thirdPartyBillOut;
    },
    //Enabled third Party Acc number to fill data for Return
    thirdPartyAccNoRetChange() {
      if (!this.thirdPartyBillRet) {
        this.thirdPartyAccNoRet = "";
        this.upsAPIThirdParPostalRet = "";
        this.upsAPIThirdParAccCountryCodeRet = "";
      } else {
        this.upsAPIThirdParAccCountryCodeRet = "USA";
      }
      this.thirdPartyAccNoRet_Enabled = this.thirdPartyBillRet;
      this.upsAPIThirdParPostalRet_Enabled = this.thirdPartyBillRet;
      this.upsAPIThirdPartyPostalCodeRet_Enabled = this.thirdPartyBillRet;
    },
    //Cancel event while adding new service level
    onClickCancel() {
      this.newAccount = false;
      this.resetFunction();
      this.$router.push("/app-service-level");
    },
    //Cancel event for popup cancel button click event
    closeDialog() {
      this.newAccount = false;
      this.accountName = "";
      this.accAllowforMultiProj = false;
      this.accountNumber = "";
      this.upsPortalName = "";
      this.upsPortalPass = "";
      this.upsAccessLicense = "";
      this.title = "";
      this.firstName = "";
      this.lastName = "";
      this.company = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.state = "";
      this.zip = "";
      this.selectedCountry = "";
      this.phone = "";
      this.email = "";
    },
    //Create new account for Return Details
    createAccountReturn() {
      this.carrierCompCalled = true;
      this.compName = "newCarrier";
      this.accountType = "";
      this.accountType = "Return";
    },
    //Create new account for Outbound Details
    createAccountOutbound() {
      this.carrierCompCalled = true;
      this.compName = "newCarrier";
      this.accountType = "";
      this.accountType = "OutBound";
    },
    //Edit Account Details Outbound
    editAccountOutbound() {
      this.carrierCompCalled = true;
      this.compName = "editCarrier";
      this.accountType = "";
      this.accountType = "OutBound";
      if (this.showUPSDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetUPSOut;
      } else if (this.showFedExDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetFedExOut;
      }
    },
    //Edit Account Details Return
    editAccountReturn() {
      this.carrierCompCalled = true;
      this.compName = "editCarrier";
      this.accountType = "";
      this.accountType = "Return";
      if (this.showUPSDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetUPSRet;
      } else if (this.showFedExDetails) {
        this.singleCarrierDet = [];
        this.singleCarrierDet = this.singleCarrierDetFedExRet;
      }
    },
    //Account selection
    async accountSelectedOut() {
      this.outboundAccName = "";
      this.outboundAccNum = "";
      this.thirdPartyBillOut = false;
      this.thirdPartyAccNoOutChange();
      if (this.selectedAccountOut !== null && this.selectedAccountOut !== undefined) {
        this.singleCarrierDetUPSOut = [];
        this.singleCarrierDetFedExOut = [];
        this.accountDetailsOut = [];
        this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === this.selectedAccountOut);
        this.outBoundAccID = this.accountDetailsOut[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(this.outBoundAccID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          this.accountSelectedDetailsOut = true;
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSOut = data[0];
            this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExOut = data[0];
            this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
          }
        }
      } else {
        this.accountSelectedDetailsOut = false;
      }
    },
    //Account Selection
    async accountSelectedRet() {
      this.returnAccName = "";
      this.returnAccNum = "";
      this.thirdPartyBillRet = false;
      this.thirdPartyAccNoRetChange();
      if (this.selectedAccountRet !== null && this.selectedAccountRet !== undefined) {
        this.singleCarrierDetUPSRet = [];
        this.singleCarrierDetFedExRet = [];
        this.accountDetailsRet = [];
        this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === this.selectedAccountRet);
        this.returnAccID = this.accountDetailsRet[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(this.returnAccID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          this.accountSelectedDetailsRet = true;
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSRet = data[0];
            this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExRet = data[0];
            this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
          }
        }
      } else {
        this.accountSelectedDetailsRet = true;
      }
    },
    //Change in Carrier Service Level API
    carrierServiceAPIChange() {
      if (this.activateCarrWebServ) {
        this.accountSelectedDetailsOut = false;
        this.thirdPartyAccNoOut_Enabled = false;
        this.upsAPIThirdParPostal_Enabled = false;
        this.upsAPIThirdPartyPostalCode_Enabled = false;
        this.accountSelectedDetailsRet = false;
        this.thirdPartyAccNoRet_Enabled = false;
        this.upsAPIThirdParPostalRet_Enabled = false;
        this.upsAPIThirdPartyPostalCodeRet_Enabled = false;
        this.thirdPartyBillOut = false;
        this.thirdPartyBillRet = false;
        this.newAccOutbound = true;
        this.newAccReturn = true;
      } else {
        this.accountSelectedDetailsOut = false;
        this.thirdPartyAccNoOut_Enabled = false;
        this.upsAPIThirdParPostal_Enabled = false;
        this.upsAPIThirdPartyPostalCode_Enabled = false;
        this.accountSelectedDetailsRet = false;
        this.thirdPartyAccNoRet_Enabled = false;
        this.upsAPIThirdParPostalRet_Enabled = false;
        this.upsAPIThirdPartyPostalCodeRet_Enabled = false;
        this.selectedAccountOut = "";
        this.outboundAccName = "";
        this.outboundAccNum = "";
        this.thirdPartyBillOut = false;
        this.thirdPartyAccNoOut = "";
        this.selectedAccountRet = "";
        this.returnAccName = "";
        this.returnAccNum = "";
        this.thirdPartyBillRet = false;
        this.thirdPartyAccNoRet = "";
        this.newAccOutbound = false;
        this.newAccReturn = false;
        if (!this.showFedExDetails) {
          this.upsAPIThirdParPostal = "";
          this.upsAPIThirdParAccCountryCode = "";
          this.upsAPIThirdParPostalRet = "";
          this.upsAPIThirdParAccCountryCodeRet = "";
        }
      }
    },
    //Add New Service level
    submitServiceLevel() {
      if (this.showNonUPSDetails) {
        if (this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        }
      } else if (this.showUPSDetails) {
        if (!this.activateCarrWebServ && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        } else if (this.$refs.UPSForm.validate() && this.$refs.carrierXilyticInfoForm.validate()) {
          if (this.thirdPartyBillOut || this.thirdPartyBillRet) {
            if (this.thirdPartyAccNoOut !== "" || this.thirdPartyAccNoRet !== "") {
              this.formValidated = true;
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "3rd Party Account Number is required please fill it",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.formValidated = false;
            }
          } else {
            this.formValidated = true;
          }
        }
      } else if (this.showFedExDetails) {
        if (!this.activateCarrWebServ && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        } else if (this.$refs.carrierXilyticInfoForm.validate() && this.$refs.fedExForm.validate()) {
          if (this.thirdPartyBillOut || this.thirdPartyBillRet) {
            if (this.thirdPartyAccNoOut !== "" || this.thirdPartyAccNoRet !== "") {
              this.formValidated = true;
            } else {
              let Alert = {
                type: "error",
                isShow: true,
                message: "3rd Party Account Number is required please fill it",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.formValidated = false;
            }
          } else {
            this.formValidated = true;
          }
        }
      } else if (this.showUSPSDetails) {
        if (this.$refs.USPSForm.validate() && this.$refs.carrierXilyticInfoForm.validate()) {
          this.formValidated = true;
        }
      } else {
        this.formValidated = false;
      }
      if (this.formValidated) {
        this.slbu_id = 0;
        this.default_sl = 0;
        this.returnServices = 0;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let newServicelevelObj = {
          InJson: JSON.stringify({
            account_id: this.accountId,
            proj_key: parseInt(this.serviceLevelsData.projKey),
            bu_key: parseInt(this.serviceLevelsData.bu_id),
            sbu_key: parseInt(this.serviceLevelsData.sbu_id),
            User_id: parseInt(this.userId),
            ord_code: this.serviceLevelsData.ord_code,
            sl_key: parseInt(this.selectedCarrier),
            slbu_id: parseInt(this.slbu_id),
            accountno: this.outboundAccNum,
            accountno2: this.outboundAccName,
            bill_3rd: this.thirdPartyBillOut ? 1 : 0,
            alt_accountno1: this.thirdPartyAccNoOut,
            alt_accountno2: "",
            return_services: this.returnServices,
            ups_zip: this.upsAPIThirdParPostal,
            ups_countrycode: this.upsAPIThirdParAccCountryCode,
            fedex_ip: "",
            slevel: this.orderReqServiceLevelCode,
            ccode: this.orderReqCarrierCode,
            active: this.activeServiceLevel ? 1 : 0,
            accountno_rtn: this.returnAccNum,
            accountno2_rtn: this.returnAccName,
            bill_3rd_rtn: this.thirdPartyBillRet ? 1 : 0,
            alt_accountno1_rtn: this.thirdPartyAccNoRet,
            alt_accountno2_rtn: "",
            ups_zip_rtn: this.upsAPIThirdParPostalRet,
            ups_countrycode_rtn: this.upsAPIThirdParAccCountryCodeRet,
            fedex_ip_rtn: "",
            api_active: this.activateCarrWebServ ? 1 : 0,
            default_sl: this.default_sl,
            outbound_account_id: parseInt(this.outBoundAccID),
            return_account_id: parseInt(this.returnAccID),
          }),
        };
        this.axios
          .post("/cp/servicelevels_upsert", newServicelevelObj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.$router.push("/app-service-level");
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //To close the components
    closeComp() {
      this.compName = "";
      this.carrierCompCalled = false;
    },
    //Carrier Added
    async carrierAdded(newCarrierID) {
      this.newCarrierID = newCarrierID;
      this.compName = "";
      this.carrierCompCalled = false;
      this.getServiceLevelDetails(newCarrierID);
    },
    //Carrier Updated
    async carrierUpdated(editCarrierID) {
      this.compName = "";
      this.carrierCompCalled = false;
      if (this.accountType == "OutBound") {
        this.accountDetailsOut = this.accountData.filter((obj) => obj.account_id === editCarrierID);
        this.selectedAccountOut = this.accountDetailsOut[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(editCarrierID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSOut = data[0];
            this.outboundAccName = this.singleCarrierDetUPSOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetUPSOut.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExOut = data[0];
            this.outboundAccName = this.singleCarrierDetFedExOut.FriendlyName;
            this.outboundAccNum = this.singleCarrierDetFedExOut.accountno;
          }
        }
      } else if (this.accountType == "Return") {
        this.accountDetailsRet = this.accountData.filter((obj) => obj.account_id === editCarrierID);
        this.selectedAccountRet = this.accountDetailsRet[0].account_id;
        let obj = {
          UserId: this.userId,
          pass: 4,
          account_id: parseInt(editCarrierID),
          acct_name: "",
          carrier_key: 0,
          proj_key: 0,
          bu_key: 0,
          accountno: "",
          client_id: "",
          client_secret: "",
          status: true,
        };
        let data = await carrierMasterCarrier.postCarrierMaster("post", obj);
        if (data.message != "NA") {
          if (this.showUPSDetails) {
            this.singleCarrierDetUPSRet = data[0];
            this.returnAccName = this.singleCarrierDetUPSRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetUPSRet.accountno;
          } else if (this.showFedExDetails) {
            this.singleCarrierDetFedExRet = data[0];
            this.returnAccName = this.singleCarrierDetFedExRet.FriendlyName;
            this.returnAccNum = this.singleCarrierDetFedExRet.accountno;
          }
        }
      }
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList != null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter != undefined && filter != null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    carrierMasterComp,
  },
};
